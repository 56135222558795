import React from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';


function Footer() {
  const handleScroll = () => {
    window.scrollTo({top:0,behavior:"smooth"});
  }

  return (
    <div className="footer-wrapper">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="topscroll" onClick={handleScroll} >Volver arriba<ArrowUpwardIcon/></a>
      <div className="cc-license">
        <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">
          <img alt="Creative Commons License" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" />
        </a> 
        <p>Licensed by <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a><br/>Created and designed by <a href="https://hardcore-darwin-24581d.netlify.app/" target="__blank">Bairon Paz </a></p>

       
      </div>

      <div className="social">
        <a href="https://www.linkedin.com/in/adrian-vico-larr%C3%BA-57a393131/" target="__blank"><LinkedInIcon aria="Linkedin"/></a>
        <a href="mailto:adrian.vico.larru@gmail.com" ><MailOutlineIcon aria="Email"/></a>
        <a href="https://www.instagram.com/avico_prod/" ><InstagramIcon aria="Email"/></a>
      </div>
    </div>
  );
}

export default Footer;
