import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from "react-router-dom";

export default function DropDown() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="dropdown">
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Categoria
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        <MenuItem ><Link onClick={handleClose} to="/galeria/">Todos</Link></MenuItem>
        <MenuItem ><Link onClick={handleClose} to="/galeria/stock">Stock</Link></MenuItem>
        <MenuItem ><Link onClick={handleClose} to="/galeria/naturaleza">Naturaleza</Link></MenuItem>
        <MenuItem ><Link onClick={handleClose} to="/galeria/particulares">Particulares</Link></MenuItem>
        <MenuItem ><Link onClick={handleClose} to="/galeria/deporte">Deporte</Link></MenuItem>
        <MenuItem ><Link onClick={handleClose} to="/galeria/evento">Evento</Link></MenuItem>
      </Menu>
    </div>
  );
}