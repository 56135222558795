import React from 'react';
import {data} from "./data";
import GalleryCarousel from './Carousel';
import {Link, useParams} from "react-router-dom";
import Image from "./Image"
import DropDown from './DropDown';

function GalleryPage() {
  const params = useParams();

  return (
    <div className="gallery-page">
      <div className="carrousel-wrapper"><GalleryCarousel  featured={data.filter(item => item.tags.includes("destacadas")).sort(() => Math.random() - 0.5).slice(0,10)}></GalleryCarousel></div>
      <div className="gallery-wrapper">
        <div className="controls">
          <div className="links">
            <Link to="/galeria/">Todos</Link>
            <Link to="/galeria/stock">Stock</Link>
            <Link to="/galeria/naturaleza">Naturaleza</Link>
            <Link to="/galeria/particulares">Particulares</Link>
            <Link to="/galeria/deporte">Deporte</Link>
            <Link to="/galeria/evento">Evento</Link>
          </div>
          <DropDown className="dropdown"/>
        </div>

        <div className="gallery">  
          {
            data.filter( item => params.id?item.tags.includes(params.id):item).map(item => {
              return (<Image item={item} key={item.id} ></Image>)
            })
          }
        </div>
      </div>
    </div>
  );
}

export default GalleryPage;
