export const data = [
   //------deporte-----//

        //PACLO ACHA//
    {   
        id:"archery01",
        title:"Pablo Acha",
        description:"Este es Pablo Acha Gonzaléz; miembro del Equipo Nacional Senior/Absoluto, Medalla de bronce en Juegos Europeos (Misk - 2019), Foto tomada - 2019-10-12",
        instagram: "https://www.instagram.com/pabloachagonzalez/",
        src:"./assets/archery01.jpg",
        tags:["deporte"]
    },
    
        //ELIA//
    {   
        id:"archery02",
        title:"Elia Canales",
        description:"Esta es Elia Canales; Miembro del Equipo Nacional Senior/Absoluto, Plata individual en los JJOO Juveniles (Buenos Aires - 2018), Foto tomada - 2019-10-12",
        instagram: "https://www.instagram.com/eliacanales01/",
        src:"./assets/archery02.jpg",
        tags:["deporte","destacadas"]
    },

        //JUN SANCHEZ//
    {   
        id:"archery05",
        title:"Jun Sanchez Equipo Nacional",
        description:"Foto tomada - 2018-03-11",
        instagram: "",
        src:"./assets/archery05.jpg",
        tags:["deporte"]
    },
    {   
        id:"archery06",
        title:"Jun Sanchez Equipo Nacional",
        description:"Foto tomada - 2018-03-11",
        instagram: "",
        src:"./assets/archery06.jpg",
        tags:["deporte","destacadas"]
    },
    {   
        id:"archery03",
        title:"Jun Sanchez Equipo Nacional",
        description:"Foto tomada - 2018-03-11",
        instagram: "",
        src:"./assets/archery03.jpg",
        tags:["deporte"]
    },
    {   
        id:"archery04",
        title:"Jun Sanchez Equipo Nacional",
        description:", Foto tomada - 2018-03-11",
        instagram: "",
        src:"./assets/archery04.jpg",
        tags:["deporte"]
    },

        //Resto de archer//
    {   
        id:"archery07",
        title:"Equipo Nacional",
        description:", Foto tomada - 2019-10-12",
        instagram: "",
        src:"./assets/archery07.jpg",
        tags:["deporte"]
    },
    {   
        id:"archery08",
        title:"Leticia",
        description:", Foto tomada - 2018-03-11",
        instagram: "",
        src:"./assets/archery08.jpg",
        tags:["deporte","destacadas"]
    },
    {   
        id:"archery09",
        title:"Bosco",
        description:", Foto tomada - 2018-03-11",
        instagram: "",
        src:"./assets/archery09.jpg",
        tags:["deporte"]
    },
    {   
        id:"archery10",
        title:"Spot the arrow",
        description:"Foto tomada - 2018-03-11",
        instagram: "",
        src:"./assets/archery10.jpg",
        tags:["deporte"]
    },

        //-----horse-----//
    {   
        id:"horse01",
        title:"Mañana de Carreras",
        description:"Foto tomada - 2018-12-28",
        instagram: "https://www.instagram.com/hipodromomadrid/",
        src:"./assets/horse01.jpg",
        tags:["deporte","destacadas"]
    },
    {   
        id:"horse02",
        title:"Mañana de Carreras",
        description:"Foto tomada - 2018-12-28",
        instagram: "https://www.instagram.com/hipodromomadrid/",
        src:"./assets/horse02.jpg",
        tags:["deporte"]
    },
    {   
        id:"horse03",
        title:"Mañana de Carreras",
        description:"Foto tomada - 2018-12-28",
        instagram: "https://www.instagram.com/hipodromomadrid/",
        src:"./assets/horse03.jpg",
        tags:["deporte"]
    },
    {   
        id:"horse04",
        title:"Mañana de Carreras",
        description:"Foto tomada - 2018-12-28",
        instagram: "https://www.instagram.com/hipodromomadrid/",
        src:"./assets/horse04.jpg",
        tags:["deporte"]
    },

        //-----waterpolo-----//
    {   
        id:"wp01",
        title:"A.R CONCEPCION",
        description:"Ese momento de adrenalina antes del partido con los tuyos, todos los que comparten horas y horas de entreno, Foto tomada - 2019-01-12",
        instagram: "https://www.instagram.com/arconcepcioncl/",
        src:"./assets/wp01.jpg",
        tags:["deporte"]
    },
    {   
        id:"wp02",
        title:"A.R CONCEPCION",
        description:"Ese momento que en cada partido es un nuevo renacer y una oportunidad de alcanzar la gloria, Foto tomada - 2019-01-12",
        instagram: "https://www.instagram.com/arconcepcioncl/",
        src:"./assets/wp02.jpg",
        tags:["deporte"]
    },
    {   
        id:"wp03",
        title:"A.R CONCEPCION",
        description:"Julen Alonso, Foto tomada - 2019-01-12",
        instagram: "https://www.instagram.com/julena1onso/",
        src:"./assets/wp03.jpg",
        tags:["deporte"]
    },
    {   
        id:"wp04",
        title:"A.R CONCEPCION",
        description:"Manuel Garcia, Foto tomada - 2019-01-12",
        instagram: "https://www.instagram.com/manuwp32/",
        src:"./assets/wp04.jpg",
        tags:["deporte"]
    },
    {   
        id:"wp05",
        title:"A.R CONCEPCION",
        description:"Axel, Foto tomada - 2019-01-12 ",
        instagram: "https://www.instagram.com/axeliano/",
        src:"./assets/wp05.jpg",
        tags:["deporte"]
    },
    //--------------------------------------------------------------------------------------------------------------------------------------------//
    //evento//
        //bodas//
    {   
        id:"bod01",
        title:"Sara y Jesus",
        description:"Boda - 2019-07-13",
        instagram: "",
        src:"./assets/bod01.jpg",
        tags:["evento","destacadas"]
    },    {   
        id:"bod02",
        title:"Sara y Jesus",
        description:"Boda - 2019-07-13",
        instagram: "",
        src:"./assets/bod02.jpg",
        tags:["evento","destacadas"]
    },    {   
        id:"bod03",
        title:"Sara y Jesus",
        description:"Boda - 2019-07-13",
        instagram: "",
        src:"./assets/bod03.jpg",
        tags:["evento"]
    },    {   
        id:"bod04",
        title:"Sara y Jesus",
        description:"Boda - 2019-07-13",
        instagram: "",
        src:"./assets/bod04.jpg",
        tags:["evento"]
    },    {   
        id:"bod05",
        title:"Sara y Jesus",
        description:"2019-07-13",
        instagram: "",
        src:"./assets/bod05.jpg",
        tags:["evento"]
    },

        //comunion Valeria//
    {   
        id:"com01",
        title:"Mi primera comunion",
        description:"Valeria - 2018-05-19 ",
        instagram: "",
        src:"./assets/com01.jpg",
        tags:["evento","destacadas"]
    },
    {   
        id:"com02",
        title:"Mi primera comunion",
        description:"Valeria - 2018-05-19 ",
        instagram: "",
        src:"./assets/com02.jpg",
        tags:["evento"]
    },
    {   
        id:"com03",
        title:"Mi primera comunion",
        description:"Valeria - 2018-05-19 ",
        instagram: "",
        src:"./assets/com03.jpg",
        tags:["evento","destacadas"]
    },
    {   
        id:"com04",
        title:"Mi primera comunion",
        description:"Valeria - 2018-05-19 ",
        instagram: "",
        src:"./assets/com04.jpg",
        tags:["evento"]
    },


    //--------------------------------------------------------------------------------------------------------------------------------------------//

    //naturaleza//
        //paisajes//
    {   
        id:"nature01",
        title:"Sunset Madrid",
        description:"Cuatro Torres Business Area - ‎2019-10-31",
        instagram: "",
        src:"./assets/nature01.jpg",
        tags:["naturaleza","destacadas"]
    },
    {   
        id:"nature02",
        title:"Ray of Sunshine",
        description:"2018-07-07",
        instagram: "",
        src:"./assets/nature02.jpg",
        tags:["naturaleza","destacadas"]
    },    
    {   
        id:"nature03",
        title:"Cliffs of Moher",
        description:"Trip to Ireland - 2019-08-07",
        instagram: "",
        src:"./assets/nature03.jpg",
        tags:["naturaleza"]
    },    
    {   
        id:"nature04",
        title:"Cliffs of Moher",
        description:"Trip to Ireland - 2019-08-07",
        instagram: "",
        src:"./assets/nature04.jpg",
        tags:["naturaleza"]
    },    
    {   
        id:"nature05",
        title:"Cliffs of Moher",
        description:"Trip to Ireland - 2019-08-07",
        instagram: "",
        src:"./assets/nature05.jpg",
        tags:["naturaleza"]
    },    
    {   
        id:"nature06",
        title:"The Madrid mountains",
        description:"",
        instagram: "",
        src:"./assets/nature06.jpg",
        tags:["naturaleza"]
    },
    {   
        id:"nature07",
        title:"Pantano de San Juan",
        description:"2018-04-30",
        instagram: "",
        src:"./assets/nature07.jpg",
        tags:["naturaleza"]
    },
    {   
        id:"nature08",
        title:"G R E E N",
        description:"2018-04-30",
        instagram: "",
        src:"./assets/nature08.jpg",
        tags:["naturaleza"]
    },


        //ANIMALES//
    {   
        id:"cat01",
        title:"Cat on Bergen",
        description:"2019-12-07",
        instagram: "",
        src:"./assets/cat01.jpg",
        tags:["naturaleza","destacadas"]
    },
    {   
        id:"cow01",
        title:"Cliffs of Moher",
        description:"",
        instagram: "",
        src:"./assets/cow01.jpg",
        tags:["naturaleza"]
    },
    {   
        id:"cow02",
        title:"Cliffs of Moher",
        description:"",
        instagram: "",
        src:"./assets/cow02.jpg",
        tags:["naturaleza","destacadas"]
    },

//--------------------------------------------------------------------------------------------------------------------------------------------//

    //particular//
        //kids//
    {   
        id:"kid01",
        title:"",
        description:"Boda - 2019-07-13",
        instagram: "",
        src:"./assets/lei01.jpg",
        tags:["particulares"]
    },   
        //hugo//
    {   
        id:"hugo1",
        title:"H U G O",
        description:"Madrid - 2018-06-24",
        instagram: "https://www.instagram.com/hugo.alrosi/",
        src:"./assets/hugo01.jpg",
        tags:["particulares","destacadas"]
    },        
    {   
        id:"hugo2",
        title:"H U G O",
        description:"Madrid - 2018-06-24",
        instagram: "https://www.instagram.com/hugo.alrosi/",
        src:"./assets/hugo02.jpg",
        tags:["particulares"]
    },      

        //leire//
    {   
        id:"lei01",
        title:"L E I R E",
        description:"LEIRE - 2018-11-09",
        instagram: "",
        src:"./assets/lei01.jpg",
        tags:["particulares","destacadas"]
    },   
    {   
        id:"lei02",
        title:"L E I R E",
        description:"LEIRE - 2018-11-09",
        instagram: "",
        src:"./assets/lei02.jpg",
        tags:["particulares"]
    },
    {   
        id:"lei03",
        title:"L E I R E",
        description:"LEIRE - 2018-11-09",
        instagram: "",
        src:"./assets/lei03.jpg",
        tags:["particulares"]
    },
    {   
        id:"lei04",
        title:"L E I R E",
        description:"LEIRE - 2018-11-09",
        instagram: "",
        src:"./assets/lei04.jpg",
        tags:["particulares"]
    },
    {   
        id:"lei05",
        title:"L E I R E",
        description:"LEIRE - 2018-11-09",
        instagram: "",
        src:"./assets/lei05.jpg",
        tags:["particulares","destacadas"]
    },
    {   
        id:"lei06",
        title:"L E I R E",
        description:"LEIRE - 2018-11-09",
        instagram: "",
        src:"./assets/lei06.jpg",
        tags:["particulares"]
    },
    {   
        id:"lei07",
        title:"L E I R E",
        description:"LEIRE - 2018-11-09",
        instagram: "",
        src:"./assets/lei07.jpg",
        tags:["particulares"]
    },
    {   
        id:"lei08",
        title:"L E I R E",
        description:"LEIRE - 2018-11-09",
        instagram: "",
        src:"./assets/lei08.jpg",
        tags:["particulares"]
    },

        //ruben//
    {   
        id:"ruben01",
        title:"R U B E N",
        description:"Ruben - 2018-10-18",
        instagram: "https://www.instagram.com/_rubengym_/",
        src:"./assets/ruben01.jpg",
        tags:["particulares"]
    },
    {   
        id:"ruben02",
        title:"R U B E N",
        description:"Ruben - 2018-10-18",
        instagram: "https://www.instagram.com/_rubengym_/",
        src:"./assets/ruben02.jpg",
        tags:["particulares"]
    },
    
        //rob//
    {   
        id:"rob01",
        title:"Dublin Businessman",
        description:"Trip to Ireland - 2019-08-07",
        instagram: "",
        src:"./assets/rob01.jpg",
        tags:["particulares"]
    },    
    {   
        id:"rob02",
        title:"Bergen streets",
        description:"Después de la lluvia ...  - 2019-12-07 -",
        instagram: "",
        src:"./assets/rob02.jpg",
        tags:["particulares"]
    },    
    {   
        id:"rob03",
        title:"London streets",
        description:"London - 2019-08-05",
        instagram: "",
        src:"./assets/rob03.jpg",
        tags:["particulares"]
    },

        //andrea//
    {   
        id:"sis01",
        title:"Portraits Bergen",
        description:"Bergen Fjellstrekninger - 2019-12-07",
        instagram: "",
        src:"./assets/sis01.jpg",
        tags:["particulares","destacadas"]
    },
    {   
        id:"sis02",
        title:"Portraits Bergen",
        description:"Bergen Fjellstrekninger - 2019-12-07",
        instagram: "",
        src:"./assets/sis02.jpg",
        tags:["particulares"]
    },
    {   
        id:"sis03",
        title:"Dublin streets",
        description:"Trip to Ireland - 2019-08-07",
        instagram: "",
        src:"./assets/sis03.jpg",
        tags:["particulares"]
    },
    {   
        id:"sis04",
        title:"Portraits Bergen",
        description:"Bergen Fjellstrekninger - 2019-12-07",
        instagram: "",
        src:"./assets/sis04.jpg",
        tags:["particulares"]
    },
    {   
        id:"sis05",
        title:"Portraits Bergen",
        description:"Bergen Fjellstrekninger - 2019-12-07",
        instagram: "sis05",
        src:"./assets/sis05.jpg",
        tags:["particulares"]
    },
    {   
        id:"sis06",
        title:"Portraits Bergen",
        description:"Bergen Fjellstrekninger - 2019-12-07",
        instagram: "",
        src:"./assets/sis06.jpg",
        tags:["particulares"]
    },
//--------------------------------------------------------------------------------------------------------------------------------------------//

    //stock//
    {   
        id:"brand01",
        title:"RYANAIR",
        description:"Airport times - 2019-12",
        instagram: "",
        src:"./assets/brand01.jpg",
        tags:["stock"]
    },
    {   
        id:"brand02",
        title:"Hard Rock",
        description:"Cafe Dublín - 2019-08-06",
        instagram: "",
        src:"./assets/brand02.jpg",
        tags:["stock"]
    },
        //-//       
    {   
        id:"build01",
        title:"PWC Tower",
        description:"Cuatro Torres Business Area - ‎2019-10-31",
        instagram: "",
        src:"./assets/build01.jpg",
        tags:["stock"]
    },
    {   
        id:"build02",
        title:"London Builds",
        description:"2019-08-04",
        instagram: "",
        src:"./assets/build02.jpg",
        tags:["stock"]
    },
    {   
        id:"build03",
        title:"London Builds",
        description:"King's Cross St Pancras 2019-08-04",
        instagram: "",
        src:"./assets/build03.jpg",
        tags:["stock"]
    },
    {   
        id:"build04",
        title:"London Builds",
        description:"2019-08-04",
        instagram: "",
        src:"./assets/build04.jpg",
        tags:["stock"]
    },
    {   
        id:"build05",
        title:"London Builds",
        description:"2019-08-04",
        instagram: "",
        src:"./assets/build05.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"build06",
        title:"London Builds",
        description:"Museo de Historia Natural de Londres - 2019-08-03",
        instagram: "",
        src:"./assets/build06.jpg",
        tags:["stock"]
    },
    {   
        id:"build07",
        title:"London Builds",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/build07.jpg",
        tags:["stock"]
    },
    {   
        id:"build08",
        title:"London Builds",
        description:"Museo de Historia Natural de Londres - 2019-08-03",
        instagram: "",
        src:"./assets/build08.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"build09",
        title:"London Builds",
        description:"Museo de Historia Natural de Londres - 2019-08-03",
        instagram: "",
        src:"./assets/build09.jpg",
        tags:["stock"]
    },
    {   
        id:"build10",
        title:"London Builds",
        description:"2019-08-04",
        instagram: "",
        src:"./assets/build10.jpg",
        tags:["stock"]
    },
        //car//
    {   
        id:"car01",
        title:"Citroën",
        description:"",
        instagram: "",
        src:"./assets/car01.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"car02",
        title:"Citroën",
        description:"",
        instagram: "",
        src:"./assets/car02.jpg",
        tags:["stock"]
    },
    {   
        id:"car03",
        title:"TAXI",
        description:"Bergen - 2019-12-07",
        instagram: "",
        src:"./assets/car03.jpg",
        tags:["stock"]
    },
        //------------//
        //esculturas
    {   
        id:"esc01",
        title:"Victoria Memorial",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/esc01.jpg",
        tags:["stock"]
    },
    {   
        id:"esc02",
        title:"Victoria Memorial",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/esc02.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"esc03",
        title:"Victoria Memorial",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/esc03.jpg",
        tags:["stock"]
    },
    {   
        id:"esc02",
        title:"Museum",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/esc02.jpg",
        tags:["stock"]
    },
        //---------//
    {   
        id:"fire01",
        title:"FIRE",
        description:"2018-07-07",
        instagram: "",
        src:"./assets/fire01.jpg",
        tags:["stock","destacados"]
    },
    {   
        id:"fire02",
        title:"FIRE",
        description:"2018-07-07",
        instagram: "",
        src:"./assets/fire02.jpg",
        tags:["stock"]
    },
        //luces//
    {   
        id:"luz01",
        title:"...",
        description:"Bergen - 2019-12-07",
        instagram: "",
        src:"./assets/luz01.jpg",
        tags:["stock","destacadas"]
    },    
    {   
        id:"luz02",
        title:"...",
        description:"Bergen - 2019-12-07",
        instagram: "",
        src:"./assets/luz02.jpg",
        tags:["stock"]
    },    
    {   
        id:"luz03",
        title:"...",
        description:"Bergen - 2019-12-07",
        instagram: "",
        src:"./assets/luz03.jpg",
        tags:["stock"]
    },    
    {   
        id:"luz04",
        title:"...",
        description:"Dublin - Trip to Ireland - 2019-08-07",
        instagram: "",
        src:"./assets/luz04.jpg",
        tags:["stock"]
    },    
    {   
        id:"luz05",
        title:"...",
        description:"Dublin - Trip to Ireland - 2019-08-07",
        instagram: "",
        src:"./assets/luz05.jpg",
        tags:["stock"]
    },    
    {   
        id:"luz05",
        title:"1 9 7 4",
        description:"Madrid - 2018-06-24",
        instagram: "",
        src:"./assets/luz06.jpg",
        tags:["stock"]
    },    
        //stopck//
    {   
        id:"stock01",
        title:"Norway",
        description:"2019-12",
        instagram: "stock01",
        src:"./assets/stock01.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"stock02",
        title:"Norway",
        description:"2019-12",
        instagram: "stock02",
        src:"./assets/stock02.jpg",
        tags:["stock"]
    },
    {   
        id:"stock03",
        title:"...",
        description:"Cuatro Torres Business Area - ‎2019-10-31",
        instagram: "",
        src:"./assets/stock03.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"stock04",
        title:"...",
        description:"Cuatro Torres Business Area - ‎2019-10-31",
        instagram: "",
        src:"./assets/stock04.jpg",
        tags:["stock"]
    },
    {   
        id:"stock05",
        title:"step by step",
        description:"Bergen - 2019-12-07",
        instagram: "stock05",
        src:"./assets/stock05.jpg",
        tags:["stock"]
    },
    {   
        id:"stock06",
        title:"London",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/stock06.jpg",
        tags:["stock"]
    },
    {   
        id:"stock07",
        title:"London",
        description:"Victoria Memorial - 2019-08-03",
        instagram: "",
        src:"./assets/stock07.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"stock08",
        title:"...",
        description:"Pantano de San Juan - 2018-07-07",
        instagram: "",
        src:"./assets/stock08.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"stock09",
        description:"Bergen - 2019-12-07",
        // eslint-disable-next-line no-dupe-keys
        description:"2019-12",
        instagram: "stock09",
        src:"./assets/stock09.jpg",
        tags:["stock"]
    },
    {   
        id:"stock10",
        title:"London",
        description:"On air - 2019-08-03",
        instagram: "",
        src:"./assets/stock10.jpg",
        tags:["stock"]
    },
    {   
        id:"stock11",
        title:"London Eye",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/stock11.jpg",
        tags:["stock"]
    },
    {   
        id:"stock12",
        title:"L I B E R T Y",
        description:"London - 2019-08-03",
        instagram: "",
        src:"./assets/stock12.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"stock13",
        title:"London Eye",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/stock13.jpg",
        tags:["stock"]
    },
    {   
        id:"stock14",
        title:"London Eye",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/stock14.jpg",
        tags:["stock"]
    },
    {   
        id:"stock15",
        title:"...",
        description:"Cuatro Torres Business Area - ‎2019-10-31",
        instagram: "",
        src:"./assets/stock15.jpg",
        tags:["stock","destacadas"]
    },
    {   
        id:"stock16",
        title:"Norway",
        description:"Bergen - 2019-12-07",
        instagram: "stock16",
        src:"./assets/stock16.jpg",
        tags:["stock"]
    },
    {   
        id:"stock17",
        title:"Norway",
        description:"Bergen - 2019-12-07",
        instagram: "stock17",
        src:"./assets/stock17.jpg",
        tags:["stock"]
    },
    {   
        id:"stock18",
        title:"London",
        description:"2019-08-03",
        instagram: "",
        src:"./assets/stock18.jpg",
        tags:["stock"]
    },
    {   
        id:"stock19",
        title:"London",
        description:"King's Cross - 2019-08-03",
        instagram: "",
        src:"./assets/stock19.jpg",
        tags:["stock"]
    },
    {   
        id:"stock20",
        title:"London",
        description:"King's Cross - 2019-08-03",
        instagram: "",
        src:"./assets/stock20.jpg",
        tags:["stock"]
    },
    {   
        id:"stock21",
        title:"London",
        description:"King's Cross - 2019-08-03",
        instagram: "",
        src:"./assets/stock21.jpg",
        tags:["stock"]
    },
    {   
        id:"stock22",
        title:"London",
        description:"King's Cross - 2019-08-03",
        instagram: "",
        src:"./assets/stock22.jpg",
        tags:["stock"]
    },
    {   
        id:"stock23",
        title:"Norway",
        description:"Bergen Fjellstrekninger - 2019-12-07",
        instagram: "stock23",
        src:"./assets/stock23.jpg",
        tags:["stock"]
    },
    {   
        id:"stock24",
        title:"Water",
        description:"2018-04-30",
        instagram: "stock24",
        src:"./assets/stock24.jpg",
        tags:["stock"]
    },

//--------------------------------------------------------------------------------------------------------------------------------------------//
]


/**
 *      id:"", Debe ser unica
        title:"", Nombre que tendra la foto
        description:"", Pequeña descripcion que tendra la foto
        src:"", Direccion relativa que tendra el archivo
        tags : [""] Etiquetas que tendra, no borrar "",

        tags preferibles : "stock","particulares","deporte","evento","naturaleza","destacadas",
 */