import React from 'react';
import {NavLink} from "react-router-dom"
// import PhotoSizeSelectActualRoundedIcon from '@material-ui/icons/PhotoSizeSelectActualRounded';
import AppsRoundedIcon from '@material-ui/icons/AppsRounded';
import RecentActorsRoundedIcon from '@material-ui/icons/RecentActorsRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';

function Nav() {
  return (
    <div className="navigation-wrapper" id="Nav">
      {/* <div className="logo"><NavLink exact to="/">Avl Photos</NavLink></div>  */}
      <div className="logo"><NavLink exact to="/"><img src={require("./assets/logo_adri_2-06.png")} alt="navigation logo"/></NavLink></div> 
      <div className="links">
        <NavLink exact to="/">Inicio</NavLink>
        <NavLink to="/galeria">Galeria</NavLink>
        <NavLink to="/acercade">Acerca de mi</NavLink>
      </div>
      <div className="mobileNav">
        <NavLink exact to="/"><HomeRoundedIcon/></NavLink>
        <NavLink to="/galeria"><AppsRoundedIcon/></NavLink>
        <NavLink to="/acercade"><RecentActorsRoundedIcon/></NavLink>
      </div>
    </div>
  );
}

export default Nav;
