import React from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import Homepage from "./components//Homepage";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import AboutPage from "./components/AboutPage";
// import './App.css';
import './App.scss';
import GalleryPage from './components/Gallerypage';



function App() {
  return (
    <Router>
      {/* Navigation */}
      <Nav></Nav>
      {/* Switches */}
      <Route exact path="/">
        <Homepage></Homepage>
      </Route>
      <Route exact path="/galeria/">
      <GalleryPage></GalleryPage>
      </Route>
      <Route exact path="/galeria/:id">
      <GalleryPage></GalleryPage>
      </Route>
      <Route exact path="/acercade">
        <AboutPage></AboutPage>
      </Route>
      <Route exact path="/contact">
        contact
      </Route>
      {/* Footer */}
      <Footer></Footer>
    </Router>



  );
}

export default App;
