import React, { useState } from 'react';
import Zoom from "react-reveal/Fade"
import InstagramIcon from '@material-ui/icons/Instagram';

function Image(props) {
    const [focus,setFocus] = useState(false);
    const handleFocus = () => {
        setFocus(!focus);
        console.log(focus);
    }

    return (
    <div className={`image-wrapper ${props.item.size?props.item.size:null}`} onClick={handleFocus}>
        {focus&&
            <Zoom right>
                <div className="focused" >
                    <img src={require(`${props.item.src}`)} alt="alt" ></img>
                    <div className="focuesedimage-info">
                        <h1>{props.item.title}</h1>
                        <p>{props.item.description}</p>
                        {props.item.instagram?<a href={props.item.instagram} taget="_blank"><InstagramIcon/></a>:null}
                    </div> 
                </div>
            </Zoom>
        }
        <img src={require(`${props.item.src}`)} alt="asd"/>
        <div className="image-info">
            <h1>{props.item.title}</h1>
            <p>{props.item.description}</p>
            {props.item.instagram?<a href={props.item.instagram} taget="_blank"><InstagramIcon/></a>:null}
        </div>  
    </div>
    )
}


export default Image;
