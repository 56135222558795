import React from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
function AboutPage() {
  return (
    <div className="About-page-wrapper">
      <div className="bckg"></div>
      <div className="About-page-content">
          <section className="top">
              <div className="text-content"><h1>Mi historia</h1>
                <p>
                Soy Adrián; desde pequeño siempre he tenido cámaras entre mis manos ya sea por mi padre u otros familiares. Hacia fotos a la familia o me dejaban a veces hacer alguna suelta pero desde hace cuatro años empecé a tomármelo enserio buscar información y empezar a hacer mis primeras fotos. Comencé a ver los fallos o las cosas que no me gustaban a mí y a la gente de esas fotos y empecé a mejorar esos fallos. Poco a poco empecé a interesarme más y más, adquirí una cámara mejor, más objetivos baterías etc.
                Comencé así a hacer fotos cuando iba de viaje a otros sitios, eventos familiares, sesiones a particulares, etc.
                <br/>
                
                </p>
                </div>
              {/* <div className="img"></div>*/}
              <div className="visual-content">
                <img src={require("../assets/fotoperfil.jpg")} alt="Portrait"/>
                <div className="social">
                  <a href="https://www.linkedin.com/in/adrian-vico-larr%C3%BA-57a393131/" target="__blank"><LinkedInIcon aria="Linkedin"/></a>
                  <a href="mailto:adrian.vico.larru@gmail.com" ><MailOutlineIcon aria="Email"/></a>
                  <a href="https://www.instagram.com/avico_prod/" target="__blank"><InstagramIcon aria="instagram"/></a>
                </div>
                <h1>Contactame</h1>
              </div>
          </section>
          <section className="bot">
              {/* <div className="img"></div> */}
                <div className="visual-content"><img src={require("../assets/fotoperfil2.jpg")} alt="Portrait"></img></div>
              <div className="text-content"><h1>Actualmente...</h1>
                <p>
                A día de hoy desde que empecé he visto como he ido mejorando y cambiando mi propio estilo así como el feedback de la gente cuando veían mis fotos y les gustaba lo que hace que tenga más y más ganas de seguir haciendo más fotos.
                <br/>Actualmente ya estoy trabajando a pequeña escala de fotógrafo tanto en eventos como haciendo sesiones para particulares.
                </p>
              </div>
          </section>
        <div className="logo">
          <img src={require("./assets/logo_adri_2-06.png")} alt="footer logo"/>              
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
