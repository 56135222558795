import React from 'react';
import {Link} from "react-router-dom"

function Homepage() {
     
  return (
    <div className="home-page-wrapper">
      <div className="hero-wrapper">
        <div className="hero-background">
          <img src={require(`../assets/fondo${Math.floor(Math.random() * 5) + 1}.jpg`)} alt="background"></img>
        </div>
        <div className="hero-content">
          <p>El tiempo es oro <span><br/>por eso lo capturo</span></p>
          <Link to="/galeria">Galeria</Link>
        </div>
      </div>

      <div className="subhero-wrapper">
        <h1>Categorias destacadas</h1>

        <div className="showcase">
        <img src={require("./assets/bod02.jpg")} alt="Showcase"/>
          <div className="content">
            <Link to="/galeria/evento" className="link">
              Eventos
            </Link>
          </div>
        </div>
        <div className="showcase">
          <img src={require("./assets/stock14.jpg")} alt="Showcase"/>
          <div className="content inv">
            <Link to="/galeria/stock">
              Stock
            </Link>
          </div>
        </div>
        <div className="showcase">
          <img src={require("./assets/sis01.jpg")} alt="Showcase"/>
          <div className="content">
            <Link to="/galeria/particulares">
              Particulares
            </Link>
          </div>
        </div>

      </div>

    </div>
  );
}

export default Homepage;
